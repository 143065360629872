import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {CompaniesContext} from "./store/companies-context";
import SearchBar from "../components/SearchBar";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Companies() {
    const companiesCtx = useContext(CompaniesContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = companiesCtx.headers;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Companies"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={companiesCtx.search} onSearch={companiesCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Companies
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={companiesCtx.addItem} startIcon={<AddIcon/>}>
                            New Company
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (companiesCtx.exportCsv) {
                                    companiesCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV Export Companies
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (companiesCtx.exportCsv) {
                                    companiesCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Companies
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS Import Companies
                            </MenuItem>

                            {companiesCtx.selected && companiesCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={companiesCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Multi Edit
                                    </MenuItem>
                                </div>
                            }
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (companiesCtx.importCsv) {
                                    companiesCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                        {/*<Button variant="contained" color="primary" onClick={(event: any) => {*/}
                        {/*    if (companiesCtx.exportCsv) {*/}
                        {/*        companiesCtx.exportCsv('csv')*/}
                        {/*    }*/}
                        {/*}}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    CSV Export Companies*/}
                        {/*</Button>*/}
                        {/*<Button variant="contained" color="primary" onClick={(event: any) => {*/}
                        {/*    if (companiesCtx.exportCsv) {*/}
                        {/*        companiesCtx.exportCsv('xsl')*/}
                        {/*    }*/}
                        {/*}}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    XLS Export Companies*/}
                        {/*</Button>*/}
                        {/*<Input*/}
                        {/*    ref={fileInput}*/}
                        {/*    accept=".csv,.xlsx,.xls"*/}
                        {/*    type="file"*/}
                        {/*    name="file"*/}
                        {/*    onChange={(event: any) => {*/}
                        {/*        if (companiesCtx.importCsv) {*/}
                        {/*            companiesCtx.importCsv(event.target.files)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<Button variant="contained" color="primary" onClick={(e: any) => {*/}
                        {/*    if (fileInput.current) {*/}
                        {/*        fileInput.current.click();*/}
                        {/*    }*/}
                        {/*}} startIcon={<FileUpload/>} className="margin-right-btn">*/}
                        {/*    CSV/XLS Import Companies*/}
                        {/*</Button>*/}
                        {/*{companiesCtx.selected && companiesCtx.selected?.length > 0 &&*/}
                        {/*    <Button variant="contained" color="primary" onClick={companiesCtx.multiEdit} className="multi-btn" startIcon={<Edit />} >*/}
                        {/*        Multi edit*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*<Button variant="contained" color="primary" onClick={companiesCtx.addItem}>*/}
                        {/*    <AddIcon/>*/}
                        {/*    New Company*/}
                        {/*</Button>*/}
                    </div>
                </Grid>
            </Grid>

            {companiesCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={companiesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={companiesCtx.removeItem}
                    onSelectHandler={companiesCtx.selectItem}
                    onPaginateHandler={companiesCtx.onPaginate}
                    onSortHandler={companiesCtx.onSort}
                    count={companiesCtx.count}
                    page={companiesCtx.page}
                    rowsPerPage={companiesCtx.rowsPerPage}
                    sorting={companiesCtx.sorting}
                    filters={companiesCtx.filters}
                    onMultiEditHandler={companiesCtx.multiSelected}
                    selectedArray = {companiesCtx.selected}
                />
            </Grid>


        </React.Fragment>
    );
}

export default Companies;
