import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";

import {Formik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import TabPanel from "../components/TabPanel";
import GeneralInfoCategory from "./fieldGroups/translate/GeneralInfoCategory";
import SeoCategory from "./fieldGroups/translate/SeoCategory";
import InlineContentElementTranslate from "../../components/inline-content-element/InlineContentElementTranslate";
import {ContentElementItemType} from "../../types/inlineContentElementType";

// GraphQL
import {
    TranslateCategoryQuery, useTranslateCategoryQuery,
    useUpdateCategoryMutation
} from "../../graphql/category/category.graphql-gen";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);

const CategoryTranslate: React.FC = () => {
    let title: string = 'New category'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();
    const model: string = 'Category';


    // Queries
    const {data: data, error: error, loading: loading, refetch: refetchCategory} = useTranslateCategoryQuery({
        variables: {id, model, language: params.lang ? params.lang : ''},
        skip: id === '',
        onCompleted: (data: TranslateCategoryQuery) => {
            setContentElements(data.translateCategory.contentElements);
            if (data.translateCategory && data.translateCategory.category) {
                setState(data.translateCategory.category);
                setInicialValue({
                    "data": data.translateCategory.category,
                    "contentElements": data.translateCategory.contentElements,
                });
            }
        }
    });
    const [updateCategory] = useUpdateCategoryMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [contentElements, setContentElements] = useState<any>(dataObj.category ? dataObj.category.contentElements : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>(params.lang ? params.lang : '')
    const [isLang, setIsLang] = useState<boolean>(false);


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error ) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data ) {
        dataObj = data
    }

    // Set title
    if (dataObj.translateCategory && dataObj.translateCategory.category) {
        title = dataObj.translateCategory.category.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveCategory = () => {
        if (id != '') {
            updateCategoryMutation(state);
        }
        setInicialValue({
            "data": state,
            "contentElements": contentElements
        });
    }

    // Update category mutation
    const updateCategoryMutation = (data: any) => {

        updateCategory({
            variables: {
                model,
                id,
                data,
                contentElements,
            }
        }).then(r => {
            setUpdated(true)

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    //Back to ListView
    const backToListView = () => {
        if(!isLang){
            navigate(`/categories`, {replace: true})
        }else if(lang==''){
            navigate(`/categories/${id}`, {replace: true})
        }else{
            navigate(`/categories/${lang}/${id}`, {replace: true});
            setOpen(false);
        }
    }
    const openDialog = () => {
            const hasChanged = !shallowEqual(inicialValue, {
                "data": state,
                "contentElements": contentElements
            });
            setIsLang(false);
            if (hasChanged) {
                setOpen(true);
            } else {
                backToListView()
            }
    }

    const openTranslateDialog = (lang: any) => {
            const hasChanged = !shallowEqual(inicialValue, {
                "data": state,
                "contentElements": contentElements
            });

            setIsLang(true);
            if (hasChanged) {
                setLang(lang);
                setOpen(true);
            } else {
                if(lang ==''){
                    navigate(`/categories/${id}`, {replace: true})
                }else{
                    navigate(`/categories/${lang}/${id}`, {replace: true})
                }
            }
    }


    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;

        if (type) {

            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            if (event.target) {
                fieldName = event.target.name;
                value = event.target.value;
            }

            if (field) {
                fieldName = field
                value = event
            }
        }
        // Update select value
        setState({...state, [fieldName]: value});

    };


    //------------------------ Inline content element methods --------------------------

    // Add content element
    const onAddContentElementHandler = (element: ContentElementItemType) => {
        setContentElements((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update content element
    const onContentElementUpdateHandler = (updateData: ContentElementItemType) => {
        const foundIndex = contentElements.findIndex((item: ContentElementItemType) => item.id == updateData.id);
        contentElements[foundIndex] = updateData;
        setContentElements(contentElements)
    }

    // Remove content element
    const onContentElementRemoveHandler = (id: number | string) => {
        setContentElements((prevState: ContentElementItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Remove media from content element
    const onContentElementMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
    }


    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    return (
        <React.Fragment>
            <Helmet title="Category"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>

                <Grid item>

                    <Stack direction="row" spacing={2}>
                        <Chip className="lang-nav"  variant="outlined" onClick={(lang) => {openTranslateDialog('') }}  clickable={true} avatar={<Avatar src={england} />} label="En" />
                        <Chip className={`lang-nav ${params.lang=='de' ? "active" : ""}`} variant="outlined" onClick={(lang) => {openTranslateDialog('de') }} clickable={true} avatar={<Avatar src={germany} />} label="De" />
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    onSubmit={onSaveCategory}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="SEO (Website)" {...tabsProps(1)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>

                                                    <GeneralInfoCategory
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />


                                                </TabPanel>

                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                SEO (Website)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <SeoCategory
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Storytelling for category promotions
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineContentElementTranslate
                                                        items={contentElements}
                                                        onAdd={onAddContentElementHandler}
                                                        onUpdate={onContentElementUpdateHandler}
                                                        onDelete={onContentElementRemoveHandler}
                                                        onMediaDelete={onContentElementMediaRemoveHandler}
                                                    />
                                                    <Grid container spacing={6} mb={10}>
                                                        <Grid item xs={12}></Grid>
                                                    </Grid>

                                                </TabPanel>

                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }

            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default CategoryTranslate;
