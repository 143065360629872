import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button, Menu, MenuItem
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {ProductsContext} from "./store/products-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, Edit, FileDownload, FileUpload, MoreHoriz, PictureAsPdf} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Products() {
    const productsCtx = useContext(ProductsContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = productsCtx.headers;
    const model = 'products';

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Products"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={productsCtx.search} onSearch={productsCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Products
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" className="margin-right-btn" onClick={productsCtx.addItem} startIcon={<AddIcon/>}>
                            New Product
                        </Button>
                        <Button
                            id="options-button"
                            aria-controls={open ? 'options-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            onClick={handleClick}
                        >
                            <MoreHoriz/>
                        </Button>
                        <Menu
                            id="options-menu"
                            MenuListProps={{
                                'aria-labelledby': 'options-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            elevation={0}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={(event: any) => {
                                if (productsCtx.exportCsv) {
                                    productsCtx.exportCsv('csv')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                CSV Export Products
                            </MenuItem>
                            <MenuItem onClick={(event: any) => {
                                if (productsCtx.exportCsv) {
                                    productsCtx.exportCsv('xls')
                                }
                                handleClose()
                            }} disableRipple>
                                <FileDownload/>
                                XLS Export Products
                            </MenuItem>

                            <MenuItem onClick={(event: any) => {
                                if (fileInput.current) {
                                    fileInput.current.click();
                                }
                                handleClose()
                            }} disableRipple>
                                <FileUpload/>
                                CSV/XLS Import Products
                            </MenuItem>

                            {productsCtx.selected && productsCtx.selected?.length > 0 &&
                                <div>
                                    <Divider sx={{my: 0.5}}/>
                                    <MenuItem onClick={productsCtx.multiEdit} disableRipple>
                                        <Edit/>
                                        Multi Edit
                                    </MenuItem>
                                    <MenuItem onClick={(event: any) => {
                                        if(productsCtx.generatePdf){
                                            productsCtx.generatePdf()
                                        }
                                        handleClose()
                                    }} disableRipple>
                                        <PictureAsPdf/>
                                        Generate Pdf
                                    </MenuItem>
                                </div>
                            }
                        </Menu>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (productsCtx.importCsv) {
                                    productsCtx.importCsv(event.target.files)
                                }
                            }}
                        />

                        {/*{productsCtx.selected && productsCtx.selected?.length > 0 &&*/}
                        {/*    <Button variant="contained" color="primary" onClick={productsCtx.generatePdf}*/}
                        {/*            startIcon={<PictureAsPdf/>} className="margin-right-btn">*/}
                        {/*        Generate Pdf*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*<Button variant="contained" color="primary" onClick={(event: any) => {*/}
                        {/*    if (productsCtx.exportCsv) {*/}
                        {/*        productsCtx.exportCsv('csv')*/}
                        {/*    }*/}
                        {/*}}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    CSV Export Products*/}
                        {/*</Button>*/}
                        {/*<Button variant="contained" color="primary" onClick={(event: any) => {*/}
                        {/*    if (productsCtx.exportCsv) {*/}
                        {/*        productsCtx.exportCsv('xls')*/}
                        {/*    }*/}
                        {/*}}*/}
                        {/*        startIcon={<FileDownload/>} className="margin-right-btn">*/}
                        {/*    XLS Export Products*/}
                        {/*</Button>*/}
                        {/*<Input*/}
                        {/*    ref={fileInput}*/}
                        {/*    accept=".csv,.xlsx,.xls"*/}
                        {/*    type="file"*/}
                        {/*    name="file"*/}
                        {/*    onChange={(event: any) => {*/}
                        {/*        if (productsCtx.importCsv) {*/}
                        {/*            productsCtx.importCsv(event.target.files)*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<Button variant="contained" color="primary" onClick={(e: any) => {*/}
                        {/*    if (fileInput.current) {*/}
                        {/*        fileInput.current.click();*/}
                        {/*    }*/}
                        {/*}} startIcon={<FileUpload/>} className="margin-right-btn">*/}
                        {/*    CSV/XLS Import Products*/}
                        {/*</Button>*/}
                        {/*{productsCtx.selected && productsCtx.selected?.length > 0 &&*/}
                        {/*    <Button variant="contained" color="primary" onClick={productsCtx.multiEdit} className="multi-btn" startIcon={<Edit />} >*/}
                        {/*        Multi edit*/}
                        {/*    </Button>*/}
                        {/*}*/}
                        {/*<Button variant="contained" color="primary" onClick={productsCtx.addItem} startIcon={<AddIcon />}>*/}
                        {/*    New Product*/}
                        {/*</Button>*/}
                    </div>
                </Grid>
            </Grid>

            {productsCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={productsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={productsCtx.removeItem}
                    onSelectHandler={productsCtx.selectItem}
                    onPaginateHandler={productsCtx.onPaginate}
                    onSortHandler={productsCtx.onSort}
                    onFilterHandler={productsCtx.onFilter}
                    count={productsCtx.count}
                    page={productsCtx.page}
                    rowsPerPage={productsCtx.rowsPerPage}
                    sorting={productsCtx.sorting}
                    filters={productsCtx.filters}
                    filter={productsCtx.filter}
                    onMultiEditHandler={productsCtx.multiSelected}
                    selectedArray = {productsCtx.selected}
                    model={model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Products;
