import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, FormControlLabel, Checkbox, FormGroup
} from "@mui/material";

import {Formik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";
import GeneralInfoArticle from "./fieldGroups/GeneralInfoArticle";
import ArticleMultiEditTranslate from "./fieldGroups/translate/ArticleMultiEditTranslate";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    ArticleMultiEditQuery,
    InlineMediaUploadArticleMutation,
    useArticleMultiEditQuery,
    useInlineMediaUploadArticleMutation,
    useMultiResetArticleMutation,
    useMultiUpdateArticleMutation,
} from "../../graphql/article/article.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({});

const ArticleMultiEdit: React.FC = () => {
    let title: string = 'Article Multi Edit'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'files'];
    const navigate = useNavigate();
    const model: string = 'Article';
    const location: any = useLocation();

    // Queries
    const {data: data, error: error, loading: loading} = useArticleMultiEditQuery({
        variables: {ids: location.state.ids, model, fields: inlineModels},
        onCompleted: (data: ArticleMultiEditQuery) => {
            setState({});
            setInicialValue({});

            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'images':
                            setImages(item.inlineMedia);
                            break;
                        case 'files':
                            setFiles(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [multiUpdateArticle] = useMultiUpdateArticleMutation();
    const [inlineMediaUpload] = useInlineMediaUploadArticleMutation();
    const [multiResetArticle] = useMultiResetArticleMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState<any>(dataObj.article ? dataObj.article.images : {});
    const [mediaItems, setMediaItems] = useState<any>(dataObj.article ? dataObj.article.images.items : []);
    const [files, setFiles] = useState<any>(dataObj.article ? dataObj.article.files : {});
    const [filesItems, setFilesItems] = useState<any>(dataObj.article ? dataObj.article.files.items : []);
    const [userId, setUserId] = useState<any>();
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);


    // Set loading
    if (loading || loader) {
        return <Loader/>
    }

    // Handle error
    if (error ) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveArticle = () => {
        updateArticleMultiEditMutation(state)
        setInicialValue({
            "data": state, "images": mediaItems, "files": filesItems
        });
    }

    // Update article mutation
    const updateArticleMultiEditMutation = (data: any) => {

        multiUpdateArticle({
            variables: {
                model,
                ids: location.state.ids,
                data,
                images: mediaItems,
                files: filesItems,
                removedMedia,
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }


    //Back to ListView
    const backToListView = () => {
        if(!isLang){
            navigate(`/articles`, {replace: true})
        }else{
            navigate(`/articles/${lang}/${id}`, {replace: true})
        }
    }
    const openDialog = () => {
            const hasChanged = !shallowEqual(inicialValue, {
                "data": state, "images": mediaItems, "files": filesItems
            });
            setIsLang(false);
            if (hasChanged) {
                setOpen(true);
            } else {
                backToListView()
            }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems, "files": filesItems
        });
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/articles/${lang}/${id}`, {replace: true})
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    const onAddFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'files',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        mediaItems[foundIndex] = updateData;
        setMediaItems(mediaItems)
    }
    const onFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = filesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        filesItems[foundIndex] = updateData;
        setFilesItems(filesItems)
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;


        if (type) {

            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox')  {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox')  {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }

        }

        // Update select value
        setState({...state, [fieldName]: value});

    };

    //------------------------------- Tabs methods -------------------------------------

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Article Multi Edit"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                    <Typography variant="h4" gutterBottom >
                        {dataObj.articleMultiEdit.map((item: any, index: number) => {
                            if(item.article ){
                                return item.article.title
                            }
                        }).join(', ')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {/*<Chip className="lang-nav active"  variant="outlined" avatar={<Avatar src={england} />} label="En" />*/}
                        {/*<Chip className="lang-nav" variant="outlined" onClick={(lang) => {openTranslateDialog('de') }} clickable={true} avatar={<Avatar src={germany} />} label="De" />*/}
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveArticle}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Media" {...tabsProps(1)} />
                                                    <Tab label="Translate Germany" {...tabsProps(2)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoArticle
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Images
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mediaItems}
                                                        fieldType={images.fieldType}
                                                        allowedExtensions={images.allowedExtensions}
                                                        isMultiple={images.isMultiple}
                                                        onAdd={onAddMediaHandler}
                                                        onUpdate={onMediaUpdateHandler}
                                                        onDelete={onMediaRemoveHandler}/>
                                                    <FormGroup className="clear-check-top">
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                name="clearImages"
                                                                checked={state.clearImages ? state.clearImages : false}
                                                                onChange={(e) =>{inputHandler(e)}}
                                                            />
                                                        } label="Clear"/>
                                                    </FormGroup>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Files
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={filesItems}
                                                        fieldType={files.fieldType}
                                                        allowedExtensions={files.allowedExtensions}
                                                        isMultiple={files.isMultiple}
                                                        onAdd={onAddFilesHandler}
                                                        onUpdate={onFilesUpdateHandler}
                                                        onDelete={onFilesRemoveHandler}/>
                                                    <FormGroup className="clear-check-top">
                                                        <FormControlLabel control={
                                                            <Checkbox
                                                                name="clearFiles"
                                                                checked={state.clearFiles ? state.clearFiles : false}
                                                                onChange={(e) =>{inputHandler(e)}}
                                                            />
                                                        } label="Clear"/>
                                                    </FormGroup>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>
                                                    <ArticleMultiEditTranslate
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                        multiEdit={true}
                                                    />
                                                </TabPanel>
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }

            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default ArticleMultiEdit;
